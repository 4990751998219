





















































import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppInputGroup from "@/components/core/AppInputGroup.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppToggleVisibility from "@/components/core/AppToggleVisibility.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import TheLogoWithText from "@/components/TheLogoWithText.vue";
import TheVerticalCenter from "@/components/TheVerticalCenter.vue";
import { resetPassword } from "@/utils/authentication";
import * as notification from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppInput,
    AppInputGroup,
    AppLabel,
    AppToggleVisibility,
    AppWidget,
    TheLogoWithText,
    TheVerticalCenter,
  },
})
export default class PasswordReset extends Vue {
  user: pro.User = {};
  notification: notification.Notification | null = null;
  showPassword = false;
  loading = false;

  get token() {
    return this.$route.params.token;
  }

  async resetPassword() {
    this.loading = true;
    try {
      await resetPassword(this.user, this.token);
      this.notification = notification.createSuccessNotification(
        "Your password has been changed."
      );
    } catch (err) {
      this.notification = notification.createErrorNotification(err.message);
    } finally {
      this.loading = false;
    }
  }
}
