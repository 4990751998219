













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppToggleVisibility extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly value!: boolean;

  get icon(): string {
    return this.value ? "eye" : "eye-slash";
  }

  get title(): string {
    return this.value ? "Hide Password" : "Show Password";
  }
}
